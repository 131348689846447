<template>
  <div class="fill">
    <el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer">
      <h3 class="loginTitle">
        高标准农田综合管理平台
      </h3>
      <el-form-item prop="username">
        <el-input type="text" v-model="loginForm.username" placeholder="请输入用户名"> </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"> </el-input>
      </el-form-item>

      <el-button type="primary" style="width:100%;margin-bottom: 24px;" @click="submitLogin">登录</el-button>
    </el-form>
  </div>
</template>

<script>
import { login } from '@/api/api.js';
export default {
  components: {},
  data() {
    return {
      captchaUrl: '',
      loginForm: {
        username: '',
        password: ''
      },
      checked: true,
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    submitLogin() {
      login({
        sid: 'pc',
        name: this.loginForm.username,
        password: this.loginForm.password
      }).then(res => {
        if (res && res.token) {
          localStorage.setItem('token', JSON.stringify({ token: res.token, user_id: res.user.id, sid: 'pc' }));
          localStorage.setItem('user', JSON.stringify(res.user));
          this.$router.replace({ path: '/' });
        }
      }).catch(error => {

      });
    }
  }
};
</script>
<style scoped lang="scss">
.fill {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: url('../../../assets/bg.jpg');
  background-size: 100%;
}

.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 15px 35px 15px 35px;
  background: aliceblue;
}

.loginTitle {
  margin: 20px auto 20px auto;
  text-align: center;
  font-size: 28px;
}

.loginRemember {
  text-align: left;
  margin: 0px 0px 15px 0px;
}
</style>
