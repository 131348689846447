<template>
  <div class="page-container">
    <el-card class="box-card">
      <span @click="goBack" class="icon-close icon iconfont icon-guanbi"></span>
      <el-form :model="detailInfo" :rules="rules" ref="detailInfo" label-width="100px" class="land-form">
        <el-form-item label="地块样貌" prop="name">
          <div class="land-img-wrap">
            <img class="land-img" :src="detailInfo.imageUrlSmall" />
            <div class="edit-btn" @click="editLandBoundary">编辑</div>
          </div>
        </el-form-item>
        <el-form-item label="地块名称" prop="name">
          <el-col :span="11"><el-input v-model="detailInfo.name"></el-input></el-col>
        </el-form-item>
        <el-form-item label="地块面积" prop="area">
          <el-col :span="11">
            <el-input type="number" v-model="detailInfo.area"></el-input>
          </el-col>
          <span class="area-unit">亩</span>
        </el-form-item>
        <el-form-item label="地址" prop="name" show-overflow-tooltip>
          <el-col :span="11"
            >{{ detailInfo.province }}{{ detailInfo.city }}{{ detailInfo.county }}{{ detailInfo.township }}{{ detailInfo.village }}</el-col
          >
        </el-form-item>
        <el-form-item label="详细地址" prop="name" show-overflow-tooltip>
          <el-col :span="11"><el-input v-model="detailInfo.address"></el-input></el-col>
        </el-form-item>
        <el-form-item label="地貌类型" prop="name">
          <el-col :span="11">
            <el-select v-model="detailInfo.landformsType" placeholder="请选择" @change="selectLfType">
              <el-option v-for="item in landFormsTypeArr" :key="item.code" :label="item.value" :value="item.code"> </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="土壤类型" prop="name">
          <el-col :span="11">
            <el-select v-model="detailInfo.soilType" placeholder="请选择" @change="selectSoilType">
              <el-option v-for="item in soilTypeArr" :key="item.code" :label="item.value" :value="item.code"> </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="地块分组" prop="landLabelIds">
          <el-col :span="11">
            <el-select multiple v-model="detailInfo.landLabelIds" placeholder="请选择">
              <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <div class="save-btn" @click="saveLandInfo">保存</div>
    </el-card>

    <el-dialog title="" :close-on-click-modal="false" :close-on-press-escape="false" width="1000px" :visible.sync="landDgVisible">
      <land-add
        :land-dg-visible="landDgVisible"
        type="edit"
        :obs-images="obsImages"
        :edit-obj="editObj"
        @closeLandDg="closeLandDg"
        v-if="landDgVisible"
      ></land-add>
    </el-dialog>
  </div>
</template>

<script>
import { landView, landUpdate, dictList, imageAll, getRequest } from '@/api/api.js';
import landAdd from './components/landAdd.vue';
export default {
  components: {
    'land-add': landAdd
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入地块名称', trigger: 'blur' }],
        area: [{ required: true, message: '请输入地块面积', trigger: 'blur' }],
        area: [{ required: true, message: '请输入地块面积', trigger: 'blur' }],
        area: [{ required: true, message: '请输入地块面积', trigger: 'blur' }]
      },
      groupList: [],
      detailInfo: {
        name: '',
        area: 0,
        soilType: '',
        landformsType: '',
        address: ''
        // address: 98.58,
        // area: 98.58,
      },
      detailInfo: {
        name: '',
        area: 0,
        soilType: '',
        landformsType: ''
        // address: 98.58,
        // address: 98.58,
        // area: 98.58,
      }, // 地块详情
      soilTypeArr: [], // 土壤类型数组
      landFormsTypeArr: [], // 地貌类型数组
      pageNum: 1,
      pageSize: 8,
      totalNum: 0,
      landDgVisible: false,
      editObj: {}, // 编辑地块时参数
      obsImages: [] // 障碍物列表
    };
  },
  filters: {
    formatDate(val) {
      return val.substr(0, 10);
    }
  },
  created() {
    this.landId = this.$route.query.landId || 38;
    this.getLandView();
    this.getDictList();
    // 获取障碍物图片
    this.getObsImages();
    this.getGropList();
  },
  mounted() {},
  methods: {
    getGropList() {
      getRequest('/api/operate/planting/land/label/page', { pageSize: 999 })
        .then((res) => {
          this.groupList = res.rows;
        })
        .catch((error) => {});
    },
    // 获取地块列表
    getLandView() {
      landView({ id: this.landId })
        .then((res) => {
          this.detailInfo = res;
        })
        .catch((error) => {});
    },
    // 选择地貌类型
    selectLfType(val) {
      this.detailInfo.landformsType = val;
    },
    // 选择土壤类型
    selectSoilType(val) {
      this.detailInfo.soilType = val;
    },
    // 保存信息
    saveLandInfo() {
      let submitInfo = Object.assign({}, this.detailInfo);
      submitInfo.boundaries.forEach((item) => {
        if (item.biasedBoundary) {
          item.points = item.biasedBoundary;
        }
      });
      submitInfo.obstacleBoundaries.forEach((item) => {
        if (item.biasedBoundary) {
          item.points = item.biasedBoundary;
        }
      });
      landUpdate(submitInfo)
        .then((res) => {
          this.$message.success('地块信息已更新成功');
        })
        .catch((error) => {});
    },
    // 类型键值对接口
    getDictList() {
      dictList()
        .then((res) => {
          this.soilTypeArr = res['soil.type'];
          this.landFormsTypeArr = res['landforms.type'];
        })
        .catch((error) => {});
    },
    // 获取所有障碍物图标
    getObsImages() {
      imageAll()
        .then((res) => {
          this.obsImages = res;
        })
        .catch((error) => {});
    },
    // 编辑地块边界
    editLandBoundary() {
      this.editObj = {
        landId: this.detailInfo.id,
        latitude: this.detailInfo.centerLat,
        longitude: this.detailInfo.centerLon
      };
      this.landDgVisible = true;
    },
    // 关闭圈地弹窗
    closeLandDg(data) {
      this.detailInfo = Object.assign(this.detailInfo, data);
      this.landDgVisible = false;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped lang="scss">
.box-card {
  width: 800px;
  margin: 0 auto;
  position: relative;
  .icon-close {
    font-size: 24px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .land-img-wrap {
    position: relative;
    width: 200px;
    .land-img {
      width: 200px;
    }
    .edit-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      width: 100px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      background: rgba(2, 185, 143, 0.8);
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }

  .save-btn {
    width: 140px;
    height: 40px;
    margin-left: 80px;
    background: #02b98f;
    border-radius: 20px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
}
.area-unit {
  margin-left: 5px;
}
.el-select {
  width: 100%;
}
</style>
