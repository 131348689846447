<template>
  <el-container class="layout-cont">
    <el-header>
      <div class="header-container">
        <div class="header-left">
          <div class="tilte">
            <img alt @click="showVerison" class="logoimg" src="@/images/logo-new.png" />
            <div class="tit-text">应用服务支撑系统</div>
          </div>
          <div class="collapse-wrap" @click="toggleCollapse">
            <i class="arrow-icon icon iconfont icon-shouqi" v-show="!isCollapse"></i>
            <i class="arrow-icon icon iconfont icon-zhankai" v-show="isCollapse"></i>
          </div>
        </div>
        <div class="nav-wrap">
          <el-dropdown>
            <img v-if="user" class="avatar-img" :src="user.headImageUrl" alt="" />
            <img v-else class="avatar-img" src="@/images/avatar.png" alt="" />

            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>版本号:{{ version }}</el-dropdown-item> -->
              <el-dropdown-item>{{ user.name }}</el-dropdown-item>
              <el-dropdown-item @click.native="toLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="auto">
        <el-scrollbar style="height: calc(100vh - 60px)">
          <el-menu
            :collapse="isCollapse"
            background-color="#1F2E54"
            active-text-color="#FFF"
            text-color="#FFF"
            :default-active="activeIndex"
            class="el-menu-vertical"
            unique-opened
            @select="selectMenuItem"
          >
            <template v-for="(item, index) in menus">
              <template v-if="item.subFunctions && item.subFunctions.length">
                <el-submenu :index="index + ''" :key="item.id">
                  <template slot="title">
                    <div class="title-flex">
                      <div class="left-icon">
                        <svg class="icon menu-icon" aria-hidden="true">
                          <use :xlink:href="item.icon"></use>
                        </svg>
                      </div>
                      <div v-show="!isCollapse" class="menu-item-title">{{ item.name }}</div>
                    </div>
                  </template>
                  <template v-for="(sub, idx) in item.subFunctions">
                    <template v-if="sub.subFunctions && sub.subFunctions.length">
                      <el-submenu :index="`${index}-${idx}` + ''" :key="sub.id">
                        <template slot="title">{{ sub.name }}</template>
                        <el-menu-item v-for="(sonSub, sonIdx) in sub.subFunctions" :index="`${index}-${idx}-${sonIdx}` + ''" :key="sonSub.id">
                          <div class="menu-item" @click="skipPage(sonSub, item)">
                            <span class="menu-item-title">{{ sonSub.name }}</span>
                          </div>
                        </el-menu-item>
                      </el-submenu>
                    </template>
                    <el-menu-item v-else :index="`${index}-${idx}` + ''" :key="sub.id">
                      <div class="menu-item" @click="skipPage(sub, item)">
                        <span class="menu-item-title">{{ sub.name }}</span>
                      </div>
                    </el-menu-item>
                  </template>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :index="index + ''" :key="item.id">
                  <div class="menu-item" @click="skipPage(item)">
                    <svg class="icon menu-icon" aria-hidden="true">
                      <use :xlink:href="item.icon"></use>
                    </svg>
                    <template slot="title">{{ item.name }}</template>
                    <span class="menu-item-title">{{ item.name }}</span>
                  </div>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-main class="main-el">
        <div class="bread-wrap" v-if="breadArr.length">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <template v-for="bread in breadArr">
              <template v-if="bread.path">
                <el-breadcrumb-item :key="bread.name" :to="{ path: bread.path }">
                  <span class="breadcrumb-active">{{ bread.name }}</span>
                </el-breadcrumb-item>
              </template>
              <el-breadcrumb-item :key="bread.name" v-else>{{ bread.name }}</el-breadcrumb-item>
            </template>
            <el-breadcrumb-item v-for="storeBreadItem in storeBread" :key="storeBreadItem.name">{{ storeBreadItem.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import res from './menu';
import { menuTree } from '@/api/api.js';
import { mapGetters } from 'vuex';
export default {
  computed: {
    storeBread() {
      return this.$store.state.breadList;
    }
  },
  created() {
    this.getList();
    this.version = process.env.VUE_APP_VERSION;
  },

  watch: {
    $route(to, from) {
      this.isActive();
    }
  },
  data() {
    return {
      menus: [],
      isCollapse: false,
      activeIndex: '0',
      user: JSON.parse(localStorage.getItem('user')),
      breadArr: [],
      version: '',
      clickTime: 0
    };
  },
  methods: {
    showVerison() {
      this.clickTime++;
      if (this.clickTime >= 3) {
        this.clickTime = 0;
        this.$message({
          message: '版本号:' + this.version,
          type: 'warning'
        });
      }
    },
    getList() {
      menuTree({})
        .then((res) => {
          console.log(res);
          this.menus = res;
          this.isActive();
        })
        .catch((res) => {})
        .catch((error) => {});
    },
    toLogout() {
      localStorage.clear();
      this.$router.replace({ path: 'login' });
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    selectMenuItem(e) {
      this.activeIndex = e;
    },
    // 跳转页面
    skipPage(menu, parentMenu) {
      if (parentMenu) {
        this.editBreadArr(menu, parentMenu);
      } else {
        this.breadArr = [];
      }
      if (menu.menuUrl) {
        this.$router.push(
          {
            path: menu.menuUrl
          },
          () => {},
          () => {}
        );
      }
    },
    isActive() {
      let to = this.$route;
      let breadArr = [];

      this.menus.forEach((menu, index) => {
        if (menu.menuUrl == to.name) {
          this.activeIndex = String(index);
        } else if (menu.subFunctions && menu.subFunctions.length) {
          menu.subFunctions.forEach((sub, idx) => {
            if (sub.menuUrl == to.name) {
              this.activeIndex = String(index + '-' + idx);
              let parentObj = {
                path: '',
                name: menu.name
              };
              let subObj = {
                path: sub.menuUrl,
                name: sub.name
              };
              breadArr.push(parentObj, subObj);
            } else if (sub.subFunctions && sub.subFunctions.length) {
              sub.subFunctions.forEach((son, sonIdx) => {
                if (son.menuUrl == to.name) {
                  this.activeIndex = String(index + '-' + idx + '-' + sonIdx);
                  let parentObj = {
                    path: menu.menuUrl,
                    name: menu.name
                  };
                  let subObj = {
                    path: menu.menuUrl,
                    name: sub.name
                  };
                  let sonObj = {
                    path: son.menuUrl,
                    name: son.name
                  };
                  breadArr.push(parentObj, subObj, sonObj);
                }
              });
            }
          });
        }
      });

      console.log(breadArr);
      this.breadArr = breadArr;
    },
    editBreadArr(menu, parentMenu) {
      let breadArr = [];
      let parentObj = {
        path: '',
        name: parentMenu.name
      };
      breadArr.push(parentObj);
      if (parentMenu.subFunctions && parentMenu.subFunctions.length) {
        parentMenu.subFunctions.forEach((sub) => {
          if (menu.parentId == parentMenu.id) {
            if (sub.id == menu.id) {
              let subObj = {
                path: sub.menuUrl,
                name: sub.name
              };
              breadArr.push(subObj);
            }
          } else {
            if (sub.id == menu.parentId) {
              let subObj = {
                path: sub.menuUrl,
                // path: '',
                name: sub.name
              };
              breadArr.push(subObj);
            }
            if (sub.subFunctions && sub.subFunctions.length) {
              sub.subFunctions.forEach((son) => {
                if (son.id == menu.id) {
                  let sonObj = {
                    path: son.menuUrl,
                    name: son.name
                  };
                  breadArr.push(sonObj);
                }
              });
            }
          }
        });
      }
      this.breadArr = breadArr;
    }
  }
};
</script>

<style scoped lang="scss">
.el-menu-vertical {
  height: calc(100vh - 60px) !important;
  visibility: auto;
  background: #1f2e54;

  .menu-icon {
    font-size: 18px;
    margin-right: 5px;
    color: #fff;
    vertical-align: middle;
  }

  // .el-menu-item {
  //   &:hover {
  //     background: #14151c !important;
  //   }
  // }

  .el-menu-item.is-active {
    background-color: #4d70ff !important;

    &:hover {
      background-color: #4d70ff !important;
    }
  }
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 220px;
}

.el-menu-vertical.el-menu--collapse {
  width: 64px;
}

.el-header {
  background: #1f2e54;
}

.header-container {
  height: 60px;
  display: flex;
  align-items: center;

  .header-left {
    display: flex;
    flex: 1;

    .tilte {
      width: 220px;
      height: 60px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #1f2e54;
      width: 220px;

      .logoimg {
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 50%;
        padding: 3px;
      }

      .tit-text {
        display: inline-block;
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        padding-left: 10px;
      }
    }

    .collapse-wrap {
      line-height: 60px;

      .arrow-icon {
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .nav-wrap {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: right;

    .avatar-img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
  }
}

.main-el {
  height: calc(100vh - 60px);
  overflow: auto;
  position: relative;
  .bread-wrap {
    padding-bottom: 20px;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 20px;
    // .is-link {

    // }
  }
  .el-breadcrumb__inner {
    color: #409eff;
  }
}
.title-flex {
  display: flex;
  align-items: center;
  .el-submenu__title {
    display: flex;
    align-items: center;
  }
  .left-icon {
    display: flex;
    align-items: center;
    height: 56px;
  }
  .menu-item-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: normal;
    line-height: 20px;
    padding-right: 10px;
    height: 56px;
  }
  .el-submenu__icon-arrow {
    position: relative;
  }
}
.breadcrumb-active {
  font-weight: 700;
  text-decoration: none;
  color: #303133;
  cursor: pointer;
}
</style>
