<template>
  <div class="page-container">
    <div class="add-land-btn-wrap">
      <el-row type="flex" justify="end">
        <el-button type="primary" size="small" @click="createOrEditLand()">新建地块</el-button>
      </el-row>
    </div>
    <template v-if="landList && landList.length">
      <el-row :gutter="30">
        <el-col :span="6" v-for="item in landList" :key="item.id">
          <div class="land-item" @click="createOrEditLand(item)">
            <div class="item-wrap">
              <div class="img-wrap">
                <img :src="item.imageUrlSmall" alt="" object-fit="cover" />
              </div>
              <div class="content-wrap">
                <div class="name one-hidden">{{ item.name }}</div>
                <div class="content-foot">
                  <div class="foot-left align-center"> <img src="@/images/area-icon.png" alt="" />{{ formatPlantArea(item.area) }}亩 </div>
                  <div class="foot-right align-center"><img src="@/images/date-icon.png" alt="" />{{ item.createTime | formatDate }}</div>
                </div>
              </div>
            </div>
            <div class="address one-hidden">
              {{ item.province }}{{ item.city }}{{ item.county }}{{ item.township }}{{ item.village }}{{ item.address }}
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="pagenation">
        <el-pagination
          v-if="totalPage > 0"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="pageNum"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </template>
    <div v-else class="no-data">
      <img style="500px;height: 500px" src="@/assets/empty.jpeg" alt="" />
    </div>
    <el-dialog title="" :close-on-click-modal="false" :close-on-press-escape="false" width="1000px" :visible.sync="landDgVisible">
      <land-add
        :land-dg-visible="landDgVisible"
        type="add"
        :obs-images="obsImages"
        @closeLandDg="closeLandDg"
        @refreshLand="getLandList"
        v-if="landDgVisible"
      ></land-add>
    </el-dialog>
  </div>
</template>

<script>
import { landList, imageAll } from '@/api/api.js';
import * as utils from '@/utils/utils.js';
import landAdd from './components/landAdd.vue';
export default {
  components: {
    'land-add': landAdd
  },
  data() {
    return {
      landList: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 8,
      totalNum: 0,
      landDgVisible: false,
      type: 'add', // add: 新增 edit: 编辑
      obsImages: [] // 障碍物列表
    };
  },
  filters: {
    formatDate(val) {
      return val.substr(0, 10);
    }
  },
  created() {
    this.getLandList();
    // 获取障碍物图片
    this.getObsImages();
  },
  mounted() {},
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    // 获取地块列表
    getLandList() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      landList(params)
        .then((res) => {
          this.pageNum = res.page;
          this.totalNum = res.total;
          this.totalPage = res.totalPage;
          this.landList = res.rows;
        })
        .catch((error) => {});
    },
    // 获取所有障碍物图标
    getObsImages() {
      imageAll()
        .then((res) => {
          this.obsImages = res;
        })
        .catch((error) => {});
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getLandList();
    },
    // 新增或编辑地块
    createOrEditLand(item) {
      // if (item) {
      //   this.editObj = {
      //     landId: item.id,
      //     latitude: item.centerLat,
      //     longitude: item.centerLon
      //   };
      //   this.type = "edit";
      //   this.$router.push({ name: 'landDetail', params: {landId: item.id}});
      //   return;
      // } else {
      //   this.type = "add";
      // }
      if (item) {
        this.$router.push({ name: 'landDetail', query: { landId: item.id } });
      } else {
        this.landDgVisible = true;
      }
    },
    // 关闭圈地弹窗
    closeLandDg() {
      this.landDgVisible = false;
    }
  }
};
</script>
<style scoped lang="scss">
.add-land-btn-wrap {
  margin-bottom: 20px;
}

.land-item {
  display: flex;
  flex-direction: column;
  color: #333;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;

  .item-wrap {
    .img-wrap {
      img {
        width: 100%;
        height: 300px;
        border-radius: 8px;
      }
    }

    .content-wrap {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 15px;
      }

      .content-foot {
        color: #7e88a8;
        display: flex;
        justify-content: space-between;

        .align-center {
          display: flex;
          align-items: center;
        }

        img {
          height: 12px;
          margin-right: 3px;
        }
      }
    }
  }

  .address {
    height: 18px;
    line-height: 18px;
    color: #7e88a8;
  }
}

.add-btn {
  position: fixed;
  right: 20px;
  bottom: 120px;
  width: 40px;
  height: 40px;
}

.one-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
