let res = {
  code: 0,
  data: {
    menus: [
      {
        ID: '2',
        path: '',
        name: '',
        meta: {
          title: '科技服务',
          icon: '#icon-keyanfuwu'
        },
        children: [
          {
            ID: 12,
            path: '',
            name: 'technologyManage',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '科技服务管理',
              icon: 'odometer',
              closeTab: false
            }
          },
          {
            ID: 121,
            path: '',
            name: 'technologyList',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '科技服务申请',
              icon: 'odometer',
              closeTab: false
            }
          }
        ]
      },
      {
        ID: 13,
        path: '',
        name: '',
        meta: {
          title: '农机服务',
          icon: '#icon-nongjifuwu1'
        },
        children: [
          {
            ID: 17,
            path: '',
            name: 'machineManage',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '农机管理',
              icon: 'odometer',
              closeTab: false
            }
          },
          {
            ID: 172,
            path: '',
            name: 'machineList',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '农机申请',
              icon: 'odometer',
              closeTab: false
            }
          }
        ]
      },
      {
        ID: 3,
        path: '',
        name: '',
        meta: {
          title: '无人机服务',
          icon: '#icon-wurenjixuntian'
        },
        children: [
          {
            ID: 17,
            path: '',
            name: 'uavManage',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '无人机管理',
              icon: 'odometer',
              closeTab: false
            }
          },
          {
            ID: 172,
            path: '',
            name: 'uavList',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '无人机申请',
              icon: 'odometer',
              closeTab: false
            }
          }
        ]
      },
      {
        ID: 4,
        path: '',
        name: 'landManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '地块管理',
          icon: '#icon-dikuai21',
          closeTab: false
        }
      },
      {
        ID: 1722,
        path: '',
        name: 'mavinManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '专家管理',
          icon: '#icon-zhuanjiazixun',
          closeTab: false
        }
      },
      {
        ID: 172,
        path: '',
        name: 'questionManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '问答管理',
          icon: '#icon-wentixiaoxi',
          closeTab: false
        }
      },
      {
        ID: 173,
        path: '',
        name: 'articleManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '文章管理',
          icon: '#icon-jieshaowendang',
          closeTab: false
        }
      },
      {
        ID: 171,
        path: '',
        name: '',
        meta: {
          title: '种植方案',
          icon: '#icon-zhongzhifangan'
        },
        children: [
          {
            ID: 1711,
            path: '',
            name: 'plantingPlant',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '种植方案管理',
              icon: 'receiving',
              closeTab: false
            }
          },

          {
            ID: 1713,
            path: '',
            name: 'relatedPlots',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '关联地块',
              icon: 'receiving',
              closeTab: false
            }
          },
          {
            ID: 1712,
            path: '',
            name: 'homework',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '作业表',
              icon: 'receiving',
              closeTab: false
            }
          }
        ]
      },

      {
        ID: 15,
        path: '',
        name: 'mettingManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '会议管理',
          icon: '#icon-huiyi',
          closeTab: false
        }
      },
      {
        ID: 16,
        path: '',
        name: 'trainingManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '培训管理',
          icon: '#icon-peixunfuwu',
          closeTab: false
        }
      },
      {
        ID: 161,
        path: '',
        name: 'supplyManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '供应管理',
          icon: '#icon-gongyingxinxi',
          closeTab: false
        }
      },
      {
        ID: 162,
        path: '',
        name: 'buyManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '求购管理',
          icon: '#icon-qiugouguanli',
          closeTab: false
        }
      },
      {
        ID: 163,
        path: '',
        name: 'stockinManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '入库品类管理',
          icon: '#icon-pinlei',
          closeTab: false
        }
      },
      {
        ID: 164,
        path: '',
        name: '',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '入库统计分析',
          icon: '#icon-chanxiaofuwu',
          closeTab: false
        },
        children: [
          {
            ID: 1611,
            path: '',
            name: 'stockManage',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '投入品电子商务',
              icon: 'receiving',
              closeTab: false
            }
          }
        ]
      },
      {
        ID: 165,
        path: '',
        name: 'doc',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '文档管理',
          icon: '#icon-pinlei',
          closeTab: false
        }
      },
      {
        ID: 168,
        path: '',
        name: 'farmWorkList',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '农事操作记录',
          icon: '#icon-shuliangtongji',
          closeTab: false
        }
      },
      {
        ID: 166,
        path: '',
        name: '',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '农产品在线交易',
          icon: '#icon-chanxiaofuwu',
          closeTab: false
        },
        children: [
          {
            ID: 1661,
            path: '',
            name: 'supplyManageAgriculture',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '农产品需求管理',
              icon: 'receiving',
              closeTab: false
            }
          }
        ]
      },
      {
        ID: 169,
        path: '',
        name: 'farmMachinery',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '自有农机管理',
          icon: '#icon-shuliangtongji',
          closeTab: false
        }
      },
      {
        ID: 170,
        path: '',
        name: 'cropCategory',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '种植作物管理',
          icon: '#icon-shuliangtongji',
          closeTab: false
        }
      },
      {
        ID: 181,
        path: '',
        name: '',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '田长制产区巡检管理系统',
          icon: '#icon-chanxiaofuwu',
          closeTab: false
        },
        children: [
          {
            ID: 1811,
            path: '',
            name: 'business',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '企业管理',
              icon: 'receiving',
              closeTab: false
            }
          },
          // {
          //   ID: 1812,
          //   path: '',
          //   name: 'department',
          //   meta: {
          //     keepAlive: false,
          //     defaultMenu: false,
          //     title: '部门管理',
          //     icon: 'receiving',
          //     closeTab: false
          //   }
          // },
          {
            ID: 1813,
            path: '',
            name: 'role',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '角色管理',
              icon: 'receiving',
              closeTab: false
            }
          },
          {
            ID: 1814,
            path: '',
            name: 'custom',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '用户管理',
              icon: 'receiving',
              closeTab: false
            }
          }
        ]
      },
      {
        ID: 191,
        path: '',
        name: '',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '农作物种植标准化前期指导',
          icon: '#icon-chanxiaofuwu',
          closeTab: false
        },
        children: [
          {
            ID: 1811,
            path: '',
            name: 'stockManage1',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '种子使用分析',
              icon: 'receiving',
              closeTab: false
            }
          },
          // {
          //   ID: 1812,
          //   path: '',
          //   name: 'department',
          //   meta: {
          //     keepAlive: false,
          //     defaultMenu: false,
          //     title: '部门管理',
          //     icon: 'receiving',
          //     closeTab: false
          //   }
          // },
          {
            ID: 1813,
            path: '',
            name: 'stockManage2',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '农药使用分析',
              icon: 'receiving',
              closeTab: false
            }
          },
          {
            ID: 1814,
            path: '',
            name: 'stockManage3',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '化肥使用分析',
              icon: 'receiving',
              closeTab: false
            }
          }
        ]
      },
      {
        ID: 192,
        path: '',
        name: 'screen?id=2',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '智能灌渠水位监测子系统',
          icon: '#icon-shuliangtongji',
          closeTab: false
        }
      },
      {
        ID: 193,
        path: '',
        name: 'screen?id=1',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '智能灌渠水质监测子系统',
          icon: '#icon-shuliangtongji',
          closeTab: false
        }
      },
      {
        ID: 167,
        path: '/maintenanceManage',
        name: 'maintenanceManage',
        meta: {
          keepAlive: false,
          defaultMenu: false,
          title: '运维管理',
          icon: '#icon-chanxiaofuwu',
          closeTab: false
        },
        children: [
          {
            ID: 1671,
            path: '/maintenanceManage',
            name: 'maintenanceManage',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '服务器运维',
              icon: 'receiving',
              closeTab: false
            }
          },
          {
            ID: 1672,
            path: '/sqlList',
            name: 'sqlList',
            meta: {
              keepAlive: false,
              defaultMenu: false,
              title: '数据库运维',
              icon: 'receiving',
              closeTab: false
            }
          }
        ]
      }
    ]
  },
  msg: '获取成功'
};

export default res;
